<template>
  <div>
    <el-row style="margin-bottom: 20px;">
      <el-col :span="17">
        <el-card style="margin-bottom: 10px">
          <search-filter
              :searchName="'搜索比赛'"
              :keywordOptions="{placeholder:'搜索比赛'}"
              :searchOptions="whereOptions.filter(d=>['diff_id' , 'match_type' , 'match_id'].indexOf(d.options.prop) > -1)"
              :otherOptions="whereOptions.filter(d=>['diff_id' , 'match_type' , 'match_id'].indexOf(d.options.prop) === -1)"
              v-model="where"
              :total="total"
              @search="$refs.table.reset(...arguments)"
              @eleChange="(v)=>{hotRecommendHeight = v ? 260 : 100}"
          ></search-filter>
        </el-card>
        <div class="operate">
          <el-button-group>
            <el-button :disabled="!$isPowers('matchCreate')" type="primary" size="small" @click="$handleRoute('teachingMatchCreate')">创建比赛</el-button>
          </el-button-group>
        </div>
      </el-col>
      <el-col :span="6" :offset="1">
        <hot-recommend :height="hotRecommendHeight" type="questOther" :where="{type:1}"></hot-recommend>
      </el-col>
    </el-row>
    <el-card>
      <com-table
          ref="table"
          :data="tableData"
          :columns="columns"
          :auto-load="false"
          :total.sync="total"
          :edit-method="handleEditChange"
      >
        <template slot="action" slot-scope="scope">
          <el-button-group>
            <el-button type="text" size="small" @click="$handleRoute({other_id:scope.row.id},'teachingMatchInfo')">查看</el-button>
            <el-button type="text" :disabled="!$isPowers('matchEdit') || scope.row.status==1 || scope.row.match_type==2" size="small" @click="$handleRoute({other_id:scope.row.id},'teachingMatchEdit')">编辑</el-button>
            <el-button type="text" :disabled="!$isPowers('matchCensus')" size="small" @click="$handleRoute({other_id:scope.row.id},'teachingMatchCensus')">答题统计</el-button>
            <el-button type="text" :disabled="scope.row.status==1 || scope.row.match_type==2" size="small" @click="$handleRoute({other_id:scope.row.id},'teachingMatchQuestion')">题目管理</el-button>
            <el-button  :disabled="!$isPowers('matchDel') || scope.row.status==1 || scope.row.match_type==2" @click="$handleConfirm('你确定删除此条比赛信息吗？','/xapi/question.other/delete',{id:scope.row.id} , ()=>{$refs.table.reload();})" type="text" size="small">删除</el-button>

          </el-button-group>
        </template>
        
      </com-table>
    </el-card>
  </div>
</template>

<script>
import SearchFilter from "@/components/com/SearchFilter";
import HotRecommend from "@/views/api/com/hotRecommend";
export default {
  components: {HotRecommend, SearchFilter},
  data(){
    return {
      hotRecommendHeight:260,
      where:{},
      whereOptions:[
        { name:'el-form-item' ,
          options:{label:'难度' , prop:'diff_id', type:'radio-group' , data:{ name:"question_other", keys:"diff_id" } , defaultValue :'全部' }
        },
        { name:'el-form-item' ,
          options:{label:'类型' , prop:'match_id', type:'radio-group'  , data:{ name:"question_other", keys:"match" } , defaultValue :'全部'}
        },
        { name:'el-form-item' ,
          options:{label:'比赛类别' , prop:'match_type', type:'radio-group'  , data:{ name:"question_other", keys:"match_type" } , defaultValue :'全部'}
        },
        { name:'el-form-item' ,
          options:{label:'年份' , prop:'time_str', type:'year' }
        },
        { name:'el-form-item' ,
          options:{label:'地区' , prop:'province_id', type:'select' , data:{ name:"question_other", keys:"province_id" , isAll:false }}
        },
      ],
      columns:[
        {prop:'status',label:'状态',width:'120' , align:'center',template:({row})=>{return {'-2':'已结束','-1':'未开始','1':'正在进行'}[row.status] || '-';}},
        {prop:'title',label:'比赛名称',minWidth:'220' , align:'center'},
        {prop:'diffStr',label:'难度',width:'120' , align:'center'},
        // {prop:'know_count',label:'知识点',width:'100' , align:'center',template:({row})=>{return (row.know_count || 0)+'个';}},
        {prop:'question_num',label:'题数',width:'100' , align:'center',template:({row})=>{return (row.question_num || 0)+'题';}},
        {prop:'activ_time',label:'时间',width:'240' , align:'center'},
        {prop:'is_openStr',label:'权限',width:'100' , align:'center'},
        {prop:'teach_name',label:'举办老师',width:'120' , align:'center'},
        // {prop:'create_date',label:'创建时间',width:'160' , align:'center'},
        {prop:'is_publish',label:'是否发布',width:'120' , slot:'switch' , align:'center',callback(props , {row}){props.disabled = (row.status==1 || row.match_type==2 ) && row.is_publish==1 }},
        {fixed:'right',label:'操作',width:'300' , slot:'action' , align:'center'},
      ],
      total:0,
      tableData: {
        url:'/xapi/question.other/myList/type/1'
      }
    }
  },
  methods: {
    //  $isEditPower(props , {row}){
    //   props.disabled = row.status==1 || row.match_type==2
     
    // },
    handleEditChange(v , ov , {row , column}){
      this.$http.request({
        url : '/xapi/question.other/updateField',
        params:{
          field:column.prop,
          id:row.id,
          value:v
        }
      }).then(res=>{
          this.$message.success(res.msg)
        
      })
      .catch(()=>{
        row[column.prop] = ov;
      })
    }
  },
}
</script>

<style scoped>

</style>